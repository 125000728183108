<template>
  <div class="p-md-3 p-0">
    <div class="row">
      <div class="col-md-12">
        <h4 class="page-title">Module Request Tracker</h4>
      </div>
    </div>
    <br/>
    <div class="card">
      <div class="card-body p-2">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Customer</th>
              <th scope="col">Module Request</th>
              <th scope="col">Part Number / VIN / Firmware Code</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="request in moduleRequestData" :key="request.id" :request-id="request.id">
              <th scope="row">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item bg-none p-1">{{ request.name }}</li>
                  <li class="list-group-item bg-none fw-normal p-1">
                    <a :href="'mailto:'+request.email">{{ request.email }}</a>
                  </li>
                  <li class="list-group-item bg-none fw-normal p-1">
                    <a :href="'tel:'+request.phone_number">{{ request.phone_number }}</a></li>
                </ul>
              </th>
              <td>
                {{ request.module_type }}
              </td>
              <td>{{ (request.part_number ? request.part_number : request.vin) }}</td>
              <td>
                <form
                v-if="request.status == appConstants.MODULE_REQUEST_STATUS.PENDING.status_id"
                @submit.prevent="updateRequestStatus(request.id, request.name, request.email, request.phone_number, request.module_type, (request.part_number ? request.part_number : request.vin), null)"
                >
                  <div class="input-group">
                    <select class="form-select" aria-label="Default select example" style="width: 120px" :id="'select-'+request.id">
                      <option :value="appConstants.MODULE_REQUEST_STATUS.PENDING.status_id">{{ appConstants.MODULE_REQUEST_STATUS.PENDING.title }}</option>
                      <!-- <option :value="appConstants.MODULE_REQUEST_STATUS.PRODUCTS_AVAILABLE.status_id">{{ appConstants.MODULE_REQUEST_STATUS.PRODUCTS_AVAILABLE.title }}</option> Send Slack Notification to CSR Team -->
                      <option :value="appConstants.MODULE_REQUEST_STATUS.AVAILABLE_IN_WAREHOUSE.status_id">{{ appConstants.MODULE_REQUEST_STATUS.AVAILABLE_IN_WAREHOUSE.title }}</option> <!-- Send Email Notification to Listing team-->
                      <option :value="appConstants.MODULE_REQUEST_STATUS.PRE_ORDER.status_id">{{ appConstants.MODULE_REQUEST_STATUS.PRE_ORDER.title }}</option> <!-- Send Email Notification to Listing team-->
                      <option :value="appConstants.MODULE_REQUEST_STATUS.NOT_SELLING.status_id">{{ appConstants.MODULE_REQUEST_STATUS.NOT_SELLING.title }}</option> <!-- Send Email Notification to Customer-->
                    </select>
                    <button type="submit" class="btn btn-primary">Save</button>
                  </div>
                </form>

                <span class="badge text-bg-secondary rounded-pill pt-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Products Available" v-if="request.status == appConstants.MODULE_REQUEST_STATUS.PRODUCTS_AVAILABLE.status_id">
                  Products Available</span>

                <span class="badge text-bg-primary rounded-pill pt-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Available in our warehouse" v-if="request.status == appConstants.MODULE_REQUEST_STATUS.AVAILABLE_IN_WAREHOUSE.status_id">
                  Available in Warehouse</span>

                <span class="badge text-bg-info rounded-pill pt-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Available in Pre-Order" v-if="request.status == appConstants.MODULE_REQUEST_STATUS.PRE_ORDER.status_id">
                Pre-Order</span>

                <span class="badge text-bg-danger rounded-pill pt-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Not Selling as of the moment" v-if="request.status == appConstants.MODULE_REQUEST_STATUS.NOT_SELLING.status_id">Not Selling</span>

                <span class="badge text-bg-success rounded-pill pt-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Completed" v-if="request.status == appConstants.MODULE_REQUEST_STATUS.COMPLETED.status_id">Completed</span>
                </td>
              <td>
                <button type="button" class="btn btn-success me-2"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Completed"
                v-if="request.status != appConstants.MODULE_REQUEST_STATUS.NOT_SELLING.status_id"
                @click="updateRequestStatus(request.id, request.name, request.email, request.phone_number, request.module_type, (request.part_number ? request.part_number : request.vin), appConstants.MODULE_REQUEST_STATUS.COMPLETED.status_id)">
                  <i class="fa-sharp-duotone fa-solid fa-circle-check"></i>
                </button>
                <button type="button" class="btn btn-danger"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Archive this request"
                @click="confirmRequestRemoval(request.id)"
                >
                <!-- v-if="request.status == appConstants.MODULE_REQUEST_STATUS.PENDING.status_id"-->
                  <i class="fa-duotone fa-solid fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <v-pagination
          v-model="currentPage"
          :pages="totalPages"
          :range-size="1"
          active-color="#DCEDFF"
          @update:modelValue="updateHandler"
        />
      </div> 
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="add-url-modal" tabindex="-1" aria-labelledby="add-url-modal-Label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="add-url-modal-Label">Provide Links to Available Products</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="addUrl()">
            <div class="input-group">
              <input type="url" class="form-control" placeholder="Provide Product Links" v-model="productURL">
              <button type="submit" class="btn btn-primary">Add Link</button>
            </div>
          </form>
          <ul class="list-group list-group-flush" >
            <li class="list-group-item d-flex" v-for="urls in productURLs" :key="urls">
              <span>{{ urls }}</span>
              <button type="button" class="btn btn-danger" @click="unlinkItem(urls)"><i class="fa-duotone fa-solid fa-link-slash"></i></button>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" @click="sendEmailToCustomer()" :disabled="!productURLs.length"><i class="fa-duotone fa-solid fa-envelope me-1"></i> Send Email</button>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script lang="js">
import { defineComponent } from 'vue'
//import UnderConstruction from '../components/UnderConstruction';
import axios from 'axios';
import Mixin from '../mixins/global.mixin'
import appConstants from '@/constants/app.constants';
import VPagination from "@hennge/vue3-pagination";
import Swal from 'sweetalert2'
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { Tooltip, Modal } from 'bootstrap'
import $ from 'jquery'
  
export default defineComponent({
  name: 'ModuleRequest',
  mixins: [Mixin],
  components: {
        //UnderConstruction
    VPagination: VPagination,
  },
  setup() {
    return {
      appConstants: appConstants,
      Email: window.Email,
      
    }
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 0,
      nextPage: 0,
      moduleRequestData: [],
      addUrlModal: null,
      productURLs: [],
      productURL: null,
      selectedItemId: null,
      selectedItem: null,
    }
  },
  methods: {
    getModuleRequest: function () {
      this.toggleFullScreenSpinner(true);
      axios.get(appConstants.SERVICES_URL+ '/api/v1/module_requests?page='+this.currentPage+"&module_type=has_module_type")
      .then((response) => {
        response.data.module_requests.forEach( (data) => {
          if (!data.module_type) {
            data.module_type = 'ECM / PCM';
          }
          this.moduleRequestData.push(data); 
        });
        this.currentPage = response.data.current_page;
        this.totalPages = response.data.total_pages;
        this.nextPage = response.data.next_page;
        this.toggleFullScreenSpinner(false);
        //console.log(response);
      });
    },
    addUrl: function () {
      if (this.productURL && (!this.productURL.includes('beta.') && !this.productURL.includes('dev.'))) {
        console.log(this.productURL);
        this.productURLs.push(this.productURL);
        this.productURLs = [...new Set(this.productURLs)]
        this.productURL = null;
      } else {
        Swal.fire({
          title: "Sorry!",
          text: "Invalid URL provided!",
          icon: "error"
        });
      }
    },
    unlinkItem: function (item) {
      this.productURLs = this.productURLs.filter((i) => i !== item);
    },
    updateHandler: function () {
      this.moduleRequestData = [];
      this.getModuleRequest();
    },
    confirmRequestRemoval: function (requestID) {
      Swal.fire({
        title: "Confirm Archive?",
        text: "Archive this module request?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Archive",
        customClass: {
          confirmButton: "bg-danger"
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.removeRequest(requestID);
        }
      });
    },
    removeRequest: function (requestID) {
      axios.delete(appConstants.SERVICES_URL+'/api/v1/module_requests/'+requestID+'?force_destroy=true')
      .then((response) => {
        if (response.data.message) {
          Swal.fire({
            title: "Archived!",
            text: "Request successfully archived!",
            icon: "success"
          });
          this.moduleRequestData = this.moduleRequestData.filter((request) => request.id !== requestID);
        }
      })
    },
    replaceItemById(id, newItem) {
      // Find the index of the item with the matching id
      const index = this.moduleRequestData.findIndex(item => item.id === id);

      // Check if the item exists in the array
      if (index !== -1) {
        // Replace the item directly
        this.moduleRequestData[index] = newItem;
      } else {
        console.error(`Item with id ${id} not found.`);
      }
    },
    updateRequestStatus: function (requestID, requestName, requestEmail, requestPhone_number, requestModule_type, requestPartNumberVIN, newStatusOveride) {
      this.selectedItemId = requestID;
      this.selectedItem = this.moduleRequestData.filter((item) => item.id == requestID)[0];
      if (newStatusOveride) {
        this.addProductURL(requestName, requestModule_type, requestPartNumberVIN, requestEmail, requestPhone_number);
      } else {
        this.updateStatus(requestID, requestName, requestEmail, requestPhone_number, requestModule_type, requestPartNumberVIN);
      }
    },
    updateStatus: function (requestID, requestName, requestEmail, requestPhone_number, requestModule_type, requestPartNumberVIN, newStatusOveride) {
      this.toggleFullScreenSpinner(true);
      let newStatus = newStatusOveride ? newStatusOveride : $('#select-'+requestID).val();
      axios({
        method: 'patch',
        url: appConstants.SERVICES_URL+'/api/v1/module_requests/'+requestID,
        data: {
            "module_requests": {
              "status": newStatus
            }
        }
      }).then((response) => {
        if (response.data) {
          this.replaceItemById(requestID, response.data);
          this.toggleFullScreenSpinner(false);
          switch (newStatus) {
            case appConstants.MODULE_REQUEST_STATUS.PENDING.status_id:
              break;
            case appConstants.MODULE_REQUEST_STATUS.AVAILABLE_IN_WAREHOUSE.status_id:
              this.sendListingTeamEmail(newStatus, requestID, requestName, requestEmail, requestPhone_number, requestModule_type, requestPartNumberVIN);
              break;
            case appConstants.MODULE_REQUEST_STATUS.PRE_ORDER.status_id:
              this.sendListingTeamEmail(newStatus, requestID, requestName, requestEmail, requestPhone_number, requestModule_type, requestPartNumberVIN);
              break;
            case appConstants.MODULE_REQUEST_STATUS.NOT_SELLING.status_id:
              this.sendCustomerEmail(requestName, requestEmail, requestModule_type, requestPartNumberVIN);
              break;
          }
        }
      });
    },
    addProductURL: function () { //requestName, requestModule_type, requestPartNumberVIN, requestEmail, requestPhone_number
      const modalElement = document.getElementById('add-url-modal');
      this.addUrlModal = new Modal(modalElement);
      //this.sendSlackNotification(requestName, requestModule_type, requestPartNumberVIN, requestEmail, requestPhone_number);
      this.productURLs = [];
      this.addUrlModal.show();
    },
    getStatusTitle: function (status_id) {
      for (const key in appConstants.MODULE_REQUEST_STATUS) {
          if (appConstants.MODULE_REQUEST_STATUS[key].status_id === status_id) {
              return appConstants.MODULE_REQUEST_STATUS[key].title;
          }
      }
      return null; // Return null if status_id is not found
    },
    sendEmailToCustomer: async function () {
        let requestedPartNumberVIN = this.selectedItem.part_number ? this.selectedItem.part_number : this.selectedItem.vin;
        let linksList = "";
        this.productURLs.forEach((urls) => {
          linksList = linksList +`<li><a href="`+urls+`">`+urls+`</a></li>`;
        });
        let promises = [];
        promises.push(axios({
          method: 'patch',
          url: appConstants.SERVICES_URL+'/api/v1/module_requests/'+this.selectedItem.id,
          data: {
            "module_requests": {
              "product_link": this.productURLs,
              "status": appConstants.MODULE_REQUEST_STATUS.COMPLETED.status_id
            }
          }
        }));
        promises.push(this.Email.send({
          SecureToken: "6babb7d9-5362-4354-a851-3c78bd3cd84c", // Or use Host, Username, and Password e2f2bcd2-23af-4d24-9cec-add830c71ecb
          To: this.selectedItem.email,
          From: "tipmrepair@gmail.com",
          Subject: "Regarding Your "+requestedPartNumberVIN+" Module Request",
          Body: `<p>Dear `+this.selectedItem.name+`,</p>
          <p>Thank you for your recent request for `+this.selectedItem.module_type+` - `+requestedPartNumberVIN+`.
            <p>I'm happy to inform you that we have products matching this part number. Kindly check them out using the following links:</p>
          <ul>
            `+linksList+`
          </ul>
          <p>If you have any further questions or need assistance, feel free to call us at 818.798.5558.</p>
          <p>Best regards,<br/>
          <p style="font-size: 12px">
          <img src="https://maksenterprisesllc.com/wp-content/uploads/2023/11/maks-enterprises-logo-light-resized.png" style="height: 30px"/><br/>
          <strong>MAKS TIPM Rebuilders</strong><br/>
          818.798.5558<br/>
          www.Tipmrebuilders.com<br/>
          9531 Cozycroft Ave. Chatsworth, CA 91311
          </p>`
        }));
        
        let results = await Promise.all(promises);
        console.log(results);
        if (results[0] && results[1]) {
          if (results[1] == "OK") {
            Swal.fire({
              title: "Awesome!",
              text: "Notification Email sent successfully to the customer!",
              icon: "success"
            });
          }
        }
    }, 
    sendCustomerEmail: function (requestor, requestorEmail, requestedModuleType, requestedPartNumberVIN, ) {
        this.Email.send({
          SecureToken: "6babb7d9-5362-4354-a851-3c78bd3cd84c", // Or use Host, Username, and Password e2f2bcd2-23af-4d24-9cec-add830c71ecb
          To: requestorEmail,
          From: "tipmrepair@gmail.com",
          Subject: "Regarding Your "+requestedPartNumberVIN+" Module Request",
          Body: `<p>Dear `+requestor+`,</p>
          <p>Thank you for your recent request for `+requestedModuleType+` - `+requestedPartNumberVIN+`.
          
          After careful consideration, we regret to inform you that we will not be selling the requested module at this time.
          <p>We apologize for any inconvenience this may cause and appreciate your understanding. Please feel free to reach out if you have any questions or if there is anything else we can assist you with.</p>
          <p>Thank you for considering us, and we hope to assist you in the future.</p>

          <p>Best regards,<br/>
          <p style="font-size: 12px">
          <img src="https://maksenterprisesllc.com/wp-content/uploads/2023/11/maks-enterprises-logo-light-resized.png" style="height: 30px"/><br/>
          <strong>MAKS TIPM Rebuilders</strong><br/>
          818.798.5558<br/>
          www.Tipmrebuilders.com<br/>
          9531 Cozycroft Ave. Chatsworth, CA 91311
          </p>`
          ,
        })
        .then((response) => {
          if (response == "OK") {
            Swal.fire({
              title: "Awesome!",
              text: "Notification Email sent successfully to the customer!",
              icon: "success"
            });
          }
        })
        .catch((error) => {
          this.message = "Failed to send email: " + error;
        });
    },
    sendListingTeamEmail: function (newStatus, requestID, requestor, requestorEmail, requestorPhone, requestedModuleType, requestedPartNumberVIN) {
      let status = this.getStatusTitle(newStatus);
      if (status) {
        this.Email.send({
          SecureToken: "6babb7d9-5362-4354-a851-3c78bd3cd84c", // Or use Host, Username, and Password e2f2bcd2-23af-4d24-9cec-add830c71ecb
          To: 'ros@tipmrebuilders.com',
          Cc: 'danilo@tipmrebuilders.com',
          From: "tipmrepair@gmail.com",
          Subject: requestedModuleType+" - Partnumber/VIN: "+requestedPartNumberVIN+' request',
          Body: `<p>Hi Listing Team,</p>
          <p>Request ID <strong>#`+requestID+`</strong> was transitioned to <strong>`+status+`</strong>.
          `+(newStatus == appConstants.MODULE_REQUEST_STATUS.PRE_ORDER.status_id ? `This module is not available but we'll special order and rebuilt it for the customer.` : `This Module is available in the warehouse but not yet listed.`)+`</p>
          <p>Customer Name: `+requestor+`<br/>
            Module Requested: `+requestedModuleType+`<br/>
            Part# / VIN: `+requestedPartNumberVIN+`<br/>
            Phone: `+requestorPhone+`<br/>
            Email: `+requestorEmail+`<br/>
          </p>
          <p>Best Regards,</p>
          <p style="font-size: 12px">
          <img src="https://maksenterprisesllc.com/wp-content/uploads/2023/11/maks-enterprises-logo-light-resized.png" style="height: 30px"/><br/>
          <strong>Maks Enterprises Inc.</strong><br/>
          ----------------------------------<br/>
          Module Request Tracker</p>`
          ,
        })
        .then((response) => {
          if (response == "OK") {
            Swal.fire({
              title: "Awesome!",
              text: "Notification Email sent successfully to the listing team!",
              icon: "success"
            });
          }
        })
        .catch((error) => {
          this.message = "Failed to send email: " + error;
        });
      }
    },
    sendSlackNotification: function (customerName, moduleType, partNumberOrVIN, customerEmail, customerPhone) {
      var jsonString = `{"blocks":[
      {"type":"section","text":{"type":"mrkdwn","text":"*`+customerName+`* has submitted an inquiry. Please confirm stock availability and contact them promptly as this could lead to a sale."}},
      {"type":"section","text":{"type":"mrkdwn","text":"Module: `+moduleType+` \\n Part Number / VIN: `+partNumberOrVIN+` \\n Email: `+customerEmail+` \\n Phone: `+customerPhone+`"}}
      ]}`;
      var bodyFormData = new FormData();
      bodyFormData.append('bodyData', jsonString);
			axios({
				method: "post",
				url: 'https://makstools.kinsta.cloud/slack/slack.php?op=send-slack-notification-module-request',
				data: bodyFormData,
				headers: { "Content-Type": "multipart/form-data" },
			}).then( (response) => {
				if (response.data) {
					Swal.fire({
            title: "Updated!",
            text: "Slack notification sent to Customer Support Team",
            icon: "success"
          });
				}
			});
    },
    triggerTootltip: function () {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));
    },
  },
  mounted() {
    this.getModuleRequest();
    setTimeout( () => {
      this.triggerTootltip();
    }, 500);
  }
});
</script>
    