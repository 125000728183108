import { createMemoryHistory, createRouter } from 'vue-router'
import ModuleRequest from '../views/ModuleRequest.vue'
import OrderReportsECM from '../views/OrderReportsECM.vue'
import OrderReportsMerger from '../views/OrderReportsMerger.vue'
import OrderReportsTIPM from '../views/OrderReportsTIPM.vue'
import ResellerApplications from '../views/ResellerApplications.vue'
import RMAOrdersTracker from '../views/RMAOrdersTracker.vue'
import TicketList from '../views/TicketList.vue'
import store from '@/store';

const routes = [
  {
    path: '/',
    redirect: store.state.lastDestination
  },
  { path: '/order-distrubution-tipm', component: OrderReportsTIPM },
  { path: '/order-distrubution-ecm', component: OrderReportsECM },
  { path: '/order-distrubution-merger', component: OrderReportsMerger },
  { path: '/module-request', component: ModuleRequest },
  { path: '/reseller-applications', component: ResellerApplications },
  { path: '/rma-tracker', component: RMAOrdersTracker },
  { path: '/ticket-list', component: TicketList },
]

const router = createRouter({
  history: createMemoryHistory(process.env.BASE_URL),
  routes,
});

export default router