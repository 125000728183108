const ECMURL = 'https://ecmrebuilders.com';
const TIPMURL = 'https://tipmrebuilders.com';
const SERVICES_URL = 'https://maksservices-whhqd.kinsta.app';
const TIPM_AUTH = 'Basic Y2tfYzY1MWQ2Mjg0MWQzMjliN2I0Y2EzYTVhOTVhZmYzODhjYjdlN2UwNjpjc19iZjQ0NGQ2MzAzYTBmOWQ1YzgwZjkwNDIwMTE0ZTgyM2Q1Y2Q5N2Qz';
const ECM_AUTH = 'Basic Y2tfYjVmM2U4YjlkMmQxZDZiNTljY2IyNzAzMWU2ZDMyNjFmZjYwMmEwNjpjc19mZjJlMTNhODNmN2JkZDMzMmQ0ZjkzM2Y3MmYzZTNlZTQ2NTBkYmJk';
const SHIPSTATION_AUTH = 'Basic OGUwMGY2ZmMwZjk4NDU2Yzk5NTMwNjkzZjg5NmE5NWQ6MTAxOWI5ZDcyNTgyNDMyOTlhZWQ4MGU0NGY1ODQ3ZjE=';
const SHIPPING_LABEL_PRINTING_PRODUCT_TIPM = {
    REPAIR: { VALUE: 557603 , TITLE: 'RMA - Repair' },
    CLONING: { VALUE: 554980, TITLE: 'RMA - Cloning' },
    STANDARD_REPLACEMENT: { VALUE: 551246 , TITLE: 'RMA - Standard Replacement'},
    IMMEDIATE_REPLACEMENT: { VALUE: 550972, TITLE: 'RMA - Immediate Replacement'},
    PRODUCT_CLONING: { VALUE: null, TITLE: 'Product for Cloning'}
}
const CSR_ORDER_TYPES = [
    { "VALUE": 557603 , "TITLE": 'RMA - Repair'},
    { "VALUE": 554980, "TITLE": 'RMA - Cloning' },
    { "VALUE": 551246 , "TITLE": 'RMA - Standard Replacement'},
    { "VALUE": 550972, "TITLE": 'RMA - Immediate Replacement'},
    { "VALUE": 0, "TITLE" : 'Normal Purchase'},
]

const CSR_ORDER_TYPES_ECM = [
    { "VALUE": 416897 , "TITLE": 'RMA - Repair'},
    { "VALUE": 411048, "TITLE": 'RMA - Standard Replacement'},
    { "VALUE": 411043, "TITLE": 'RMA - Immediate Replacement'},
    { "VALUE": 0, "TITLE" : 'Normal Purchase'},
]

const CSR_AGENTS_LOOKUP = [
    {
        id: '12649',
        email: 'henry@tipmrebuilders.com',
        image: 'default.png',
        name: "Henry Yee"
    },
    {
        id: '12778',
        email: 'amal@tipmrebuilders.com',
        image: 'amal.jpeg',
        name: "Amal Conception"
    },
    {
        id: '12987',
        email: 'chase@tipmrebuilders.com',
        image: 'chase.jpeg',
        name: "Chase Conner"
    },
    {
        id: '12986',
        email: 'toni@maksdts.com',
        image: 'jestoni.jpeg',
        name: "Jestoni Aliñabo"
    },
    {
        id: '12981',
        email: 'franz@maksdts.com',
        image: 'franz.jpeg',
        name: "Franz Benarao"
    },
    {
        id: '12982',
        email: 'wayne@maksdts.com',
        image: 'wayne.jpeg',
        name: "Wayne Maglinte"
    },
    {
        id: '12767',
        email: 'greg@maksdts.com',
        image: 'greg.jpeg',
        name: "Greg Joseph Medel"
    },
    {
        id: '12985',
        email: 'jevic@maksdts.com',
        image: 'jevic.jpeg',
        name: "Jevic Venenoso"
    },
    {
        id: '12991',
        email: 'charlesdeo@maksdts.com',
        image: 'charlesdeo.jpeg',
        name: "Charlesdeo Bansi"
    }
]

const CSR_AGENTS_LOOKUP_ECM = [
    {
        id: '11934',
        email: 'henry@tipmrebuilders.com',
        image: 'default.png',
        name: "Henry Yee"
    },
    {
        id: '11956',
        email: 'amal@tipmrebuilders.com',
        image: 'amal.jpeg',
        name: "Amal Conception"
    },
    {
        id: '11998',
        email: 'chase@tipmrebuilders.com',
        image: 'chase.jpeg',
        name: "Chase Conner"
    },
    {
        id: '11997',
        email: 'toni@maksdts.com',
        image: 'jestoni.jpeg',
        name: "Jestoni Aliñabo"
    },
    {
        id: '11993',
        email: 'franz@maksdts.com',
        image: 'franz.jpeg',
        name: "Franz Benarao"
    },
    {
        id: '11994',
        email: 'wayne@maksdts.com',
        image: 'wayne.jpeg',
        name: "Wayne Maglinte"
    },
    {
        id: '11955',
        email: 'greg@maksdts.com',
        image: 'greg.jpeg',
        name: "Greg Joseph Medel"
    },
    {
        id: '12985',
        email: 'jevic@maksdts.com',
        image: 'jevic.jpeg',
        name: "Jevic Venenoso"
    },
    {
        id: '12000',
        email: 'charlesdeo@maksdts.com',
        image: 'charlesdeo.jpeg',
        name: "Charlesdeo Bansi"
    }
];

const MODULE_REQUEST_STATUS = {
    PENDING: {
        status_id: 'pending',
        title: 'Pending'
    },
    AVAILABLE_IN_WAREHOUSE: {
        status_id: 'available_in_warehouse',
        title: 'Available in Warehouse'
    },
    PRE_ORDER: {
        status_id: 'pre_order',
        title: 'Pre-Order'
    },
    NOT_SELLING: {
        status_id: 'not_selling',
        title: 'Not Selling'
    },
    COMPLETED: {
        status_id: 'completed',
        title: 'Completed'
    },
    PRODUCTS_AVAILABLE: {
        status_id: 'product_listed',
        title: 'Products Avaialable'
    }
}


export default {
    ECMURL: ECMURL,
    TIPMURL: TIPMURL,
    TIPM_AUTH: TIPM_AUTH,
    ECM_AUTH: ECM_AUTH,
    SHIPPING_LABEL_PRINTING_PRODUCT_TIPM: SHIPPING_LABEL_PRINTING_PRODUCT_TIPM,
    SHIPSTATION_AUTH: SHIPSTATION_AUTH,
    CSR_AGENTS_LOOKUP: CSR_AGENTS_LOOKUP,
    CSR_AGENTS_LOOKUP_ECM: CSR_AGENTS_LOOKUP_ECM,
    CSR_ORDER_TYPES_ECM: CSR_ORDER_TYPES_ECM,
    CSR_ORDER_TYPES: CSR_ORDER_TYPES,
    SERVICES_URL: SERVICES_URL,
    MODULE_REQUEST_STATUS: MODULE_REQUEST_STATUS
}