import { createStore } from 'vuex'
import persistedStateVuex from "persisted-state-vuex";


export default createStore({
  state: {
    isCollapse: false,
    isLoading: false,
    activeTab: "1",
    lastDestination: '/module-request'
  },
  mutations: {
    SET_SIDEBAR_TOGGLE: function (state, isCollapse) {
      state.isCollapse = isCollapse;
    },
    TOGGLE_FULLSCREEN_SPINNER: function (state, isLoading) {
      state.isLoading = isLoading;
    },
    CURRENT_ACTIVE_TAB: function (state, activeTab) {
      state.activeTab = activeTab;
    },
    LAST_ACTIVE_TAB: function (state, lastDestination) {
      state.lastDestination = lastDestination;
    }
  },
  getters: {

  },
  actions: {

  },
  modules: {

  },
  plugins: [persistedStateVuex.init],
})
