<template>
  <div class="p-md-3 p-0">
    <div class="row">
      <div class="col-md-7">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-md-5">
        <form @submit.prevent="submitDateRange">
          <div class="d-flex">
            <div class="input-group me-2">
              <span class="input-group-text d-none d-md-block bg-primary text-white" id="basic-addon1">Start</span>
              <input type="date" class="form-control" v-model="dateFrom">
            </div>
            <div class="input-group me-2">
              <span class="input-group-text d-none d-md-block bg-primary text-white" id="basic-addon1">End</span>
              <input type="date" class="form-control" v-model="dateTo">
            </div>
            <div>
              <button type="submit" class="btn btn-primary rounded-1" :disabled="(dateFrom == null || dateTo == null)"><i class="fa-sharp fa-regular fa-filters"></i></button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-5">
        <div class="row">

          <!-- Customer Orders -->
          <div class="col-md-6 cursor-pointer" data-bs-toggle="modal" data-bs-target="#customerOrders">
              <div class="card widget-flat">
                  <div class="card-body">
                      <div class="float-end">
                        <div class="rounded-2"><i class="fa-duotone fa-user-group text-primary mdi mdi-account-multiple widget-icon"></i></div>
                      </div>
                      <h5 class="text-muted fw-normal mt-0" title="Number of made by Customers">Customer Orders</h5>
                      <h3 class="mt-3 mb-3 text-main-dark fw-bold">{{ this.customerOrders.length }}</h3>
                      <div class="d-flex justify-content-start align-items-center m-0 p-0">
                        <p class="mb-0 text-muted ">
                            <span class="text-success me-2" v-if="customerOrders.length"><i class="mdi mdi-arrow-up-bold"></i> {{ round((this.customerOrders.length / this.wooOrderList.length) * 100, 2) }}%</span>
                            <span class="text-nowrap">Orders made by customers</span> 
                        </p>
                        <bounce-loader :loading="isOrdersLoading" color="#51E1E5" size="15px" class="ms-3 float-end"/>
                      </div>
                  </div> <!-- end card-body-->
              </div> <!-- end card-->
          </div> <!-- end col-->

          <!-- CSR Orders -->
          <div class="col-md-6 cursor-pointer" data-bs-toggle="modal" data-bs-target="#csrOrders">
              <div class="card widget-flat">
                  <div class="card-body">
                      <div class="float-end">
                        <div class="rounded-2 "><i class="fa-duotone fa-user-headset text-primary mdi mdi-account-multiple widget-icon"></i></div>
                      </div>
                      <h5 class="text-muted fw-normal mt-0" title="Number of made by Customers">CSR Handled Orders</h5>
                      <h3 class="mt-3 mb-3 fw-bold text-main-dark">{{ this.csrOrders.length }}</h3>
                      <div class="d-flex justify-content-start align-items-center m-0 p-0">
                        <p class="mb-0 text-muted d-flex justify-content-center align-items-center">
                            <span class="text-success me-2" v-if="this.csrOrders.length"><i class="mdi mdi-arrow-down-bold"></i> {{ round((this.csrOrders.length / this.wooOrderList.length) * 100, 2) }}%</span>
                            <span class="text-nowrap">Orders handled by CSRs</span>
                            
                        </p>
                        <bounce-loader :loading="isOrdersLoading" color="#51E1E5" size="15px" class="ms-3"/>
                      </div>
                  </div> <!-- end card-body-->
              </div> <!-- end card-->
          </div> <!-- end col-->

          <!-- Total Sales -->
          <div class="col-md-6">
              <div class="card widget-flat">
                  <div class="card-body">
                      <div class="float-end">
                        <div class="rounded-2"><i class="fa-duotone fa-dollar-sign text-primary mdi mdi-account-multiple widget-icon"></i></div>
                      </div>
                      <h5 class="text-muted fw-normal mt-0" title="Number of Customers">Total Sales</h5>
                      <h3 class="mt-3 mb-3 text-main-dark fw-bold">${{ numberFormater.format(totalSales) }}</h3>
                      <p class="mb-0 text-muted">
                          <span class="text-success me-2"><i class="mdi mdi-arrow-up-bold"></i> --</span>
                          <span class="text-nowrap">Total sales in {{ Object.keys(totalSalesBreakDown).length }} days</span>  
                      </p>
                  </div> <!-- end card-body-->
              </div> <!-- end card-->
          </div> <!-- end col-->

          <!-- Total Shipping Cost -->
          <div class="col-md-6">
              <div class="card widget-flat position-relative">
                  <div class="card-body">
                      <div class="float-end">
                        <div class="rounded-2"><i class="fa-duotone fa-money-check-dollar text-primary mdi mdi-account-multiple widget-icon"></i></div>
                      </div>
                      <h5 class="text-muted fw-normal mt-0" title="Number of Customers">Total Shipping Cost</h5>
                      <h3 class="mt-3 mb-3 text-main-dark fw-bold">${{ numberFormater.format(totalShipping) }}</h3>
                      <p class="mb-0 text-muted">
                          <span class="text-success me-2"><i class="mdi mdi-arrow-up-bold"></i> --</span>
                          <span class="text-nowrap">Shipping cost estimate</span>  
                      </p>
                  </div> <!-- end card-body-->
              </div> <!-- end card-->
          </div> <!-- end col-->
        </div>
      </div>
      <div class="col-md-7">
        <div class="card">
            <div class="d-flex card-header justify-content-between align-items-center">
                <h4 class="header-title">Orders Heat Map</h4>
            </div>
            <div class="card-body p-0">
                <div id="chartdiv"></div>
            </div> 
        </div>
      </div>
      <div class="col-md-5">
        <div class="card">
            <div class="d-flex card-header justify-content-between align-items-center">
                <h4 class="header-title">Most Searched Vehicles</h4>
            </div>
            <div class="card-body p-2">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="w-100 row">
                    <div class="col-2 fw-bold">Rank</div>
                    <div class="col-7 fw-bold">Vehicle</div>
                    <div class="col-3 fw-bold">Search Count</div>
                  </div>
                </li>
                <li class="list-group-item" v-for="(mostSearched, mostSearchIndex) in top10MostSearchedVehicles" :key="mostSearchIndex">
                  <div class="w-100 row">
                    <div class="col-2">{{ mostSearchIndex+1 }}</div>
                    <div class="col-7">{{ mostSearched.vehicle }}</div>
                    <div class="col-3">{{ mostSearched.search_count }}</div>
                  </div>
                </li>
              </ul>
            </div> 
        </div>
        <div class="card" >
          <div class="d-flex card-header justify-content-between align-items-center">
              <h4 class="header-title">Lid Color Distribution</h4>
          </div>
          <div class="card-body p-3" v-if="lidData">
            <Pie :data="lidData" :options="options" v-if="!lidStatsLoading"/>
          </div> 
        </div>
      </div>
      <div class="col-md-7">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
                <div class="d-flex card-header justify-content-between align-items-center">
                    <h4 class="header-title">Vehicle Configuration Stats</h4>
                </div>
                <div class="card-body p-3" v-if="rvcData">
                  <Pie :data="rvcData" :options="options" v-if="!rvcStatsLoading"/>
                </div> 
            </div>
          </div>
          <div class="col-md-6">
            <div class="card" >
                <div class="d-flex card-header justify-content-between align-items-center">
                    <h4 class="header-title">Provide VIN Method Stats</h4>
                </div>
                <div class="card-body p-3" v-if="provideYourVinTracker">
                  <Pie :data="provideYourVinTracker" :options="options"/>
                </div> 
            </div>
          </div>
        </div>
        <div class="card">
            <div class="d-flex card-header justify-content-between align-items-center">
                <h4 class="header-title">Best Selling Products</h4>
            </div>
            <div class="card-body p-2">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="w-100 row">
                    <div class="col-2 fw-bold">Rank</div>
                    <div class="col-4 fw-bold">Product Name</div>
                    <div class="col-4 fw-bold">SKU</div>
                    <div class="col-2 fw-bold">Purchase Count</div>
                  </div>
                </li>
                <li class="list-group-item" v-for="(topSeller, topSellingIndex) in top10SellingProducts" :key="topSellingIndex">
                  <div class="w-100 row">
                    <div class="col-2">{{ topSellingIndex+1 }}</div>
                    <div class="col-4">{{ topSeller.product_name }}</div>
                    <div class="col-4">{{ topSeller.sku }}</div>
                    <div class="col-2">{{ topSeller.total_sales }}</div>
                  </div>
                </li>
              </ul>
            </div> 
        </div>
      </div>
    </div>
    <!-- Order Modal -->
    <div class="modal" id="customerOrders">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"> ORDERS HANDLED BY CUSTOMERS</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-3">
            <div class="row">

                <div class="mb-2">
                  <form>
                      <div class="input-group">
                          <input type="search" class="form-control dropdown-toggle" placeholder="Search Order Number..." v-model="seachingForOrderNumberCustomer">
                          <span class="mdi mdi-magnify search-icon"></span>
                          <button class="input-group-text btn btn-primary" type="submit">Search</button>
                      </div>
                  </form>
                </div>

                <div v-if="isOrdersLoading" class="d-flex justify-content-center align-items-center">
                  <bounce-loader :loading="isOrdersLoading" color="#51E1E5" size="80px" class="ms-3 float-end" ></bounce-loader>
                </div>
                <table class="table" v-else>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="od in filteredCustomerOrders" :key="od.id">
                      <th scope="row"><a :href="baseURL+'/wp-admin/post.php?post='+od.id+'&action=edit'" target="_blank">{{ od.number }}</a></th>
                      <td>{{ od.billing.first_name+' '+od.billing.last_name}}</td>
                      <td>{{ od.date_created_gmt.split('T')[0] }}</td>
                    </tr>
                  </tbody>
                </table>

              
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <div class="modal" id="csrOrders">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"> ORDERS HANDLED BY CSRs</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-3">
            <div class="row">
              
              <div class="col-md-4">
                <h6 class="modal-title mb-2 d-flex justify-content-between align-items-center">
                  <span>FILTER BY AGENT</span>
                  <button class="btn btn-primary ms-2 btn-sm p-1" @click="resetCSRAgentFilter">
                    <i class="fa-sharp fa-regular fa-filter-slash"></i>
                  </button>
                </h6>             
                <ul class="list-group list-group-flush mb-2">
                  <li class="list-group-item justify-content-start align-items-center d-flex" v-for="csr in csrAgentLst" :key="csr.id">
                    <input class="form-check-input" type="checkbox" :value="csr.email" :id="'csr-'+csr.id" v-model="selectedCSR" checked>
                    <label class="form-check-label ms-3" :for="'csr-'+csr.id" style="font-weight: normal !important;">
                      <img :src="'./csrs/'+csr.image" style="height: 35px" class="rounded-circle me-2"/> {{ csr.name }}
                    </label>
                  </li>
                </ul>
                <h6 class="modal-title mb-2 d-flex justify-content-between align-items-center">
                  <span>FILTER BY ORDER TYPE</span>
                  <button class="btn btn-primary ms-2 btn-sm p-1" @click="resetOrderTypeFilter">
                    <i class="fa-sharp fa-regular fa-filter-slash"></i>
                  </button>
                </h6>
                <ul class="list-group list-group-flush mb-2">
                  <li class="list-group-item justify-content-start align-items-center d-flex" v-for="(ot, index) in csrOrderTypes" :key="index">
                    <input class="form-check-input" type="checkbox" :value="ot.TITLE" :id="'order-type-'+ot.VALUE" v-model="selectedOrderTypes" checked>
                    <label class="form-check-label ms-3" :for="'order-type-'+ot.VALUE" style="font-weight: normal !important;">
                      {{ ot.TITLE }}
                    </label>
                  </li>
                  
                </ul>
              </div>
              
              <div class="col-md-8">
                <div class="mb-2">
                  <form>
                      <div class="input-group">
                          <input type="search" class="form-control dropdown-toggle" placeholder="Search Order Number..." v-model="seachingForOrderNumber">
                          <span class="mdi mdi-magnify search-icon"></span>
                          <button class="input-group-text btn btn-primary" type="submit">Search</button>
                          <button class="input-group-text btn btn-success" @click="exportTableToCsv('order-handled-bycsr-tipm')"><i class="fa-sharp fa-solid fa-file-excel"></i></button>
                      </div>
                  </form>
                </div>
                <div v-if="isOrdersLoading" class="d-flex justify-content-center align-items-center">
                  <bounce-loader :loading="isOrdersLoading" color="#51E1E5" size="80px" class="ms-3 float-end" ></bounce-loader>
                </div>
                <table class="table" id="order-handled-bycsr-tipm" v-else>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Handled By</th>
                      <th scope="col">Order Type</th>
                      <th scope="col">Date</th>
                      <th scope="col" class="d-none">Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="od in filteredCSROrders" :key="od.id">
                      <th scope="row"><a :href="baseURL+'/wp-admin/post.php?post='+od.id+'&action=edit'" target="_blank">{{ od.number }}</a></th>
                      <td><img :src="'./csrs/'+od.csrAgentImg" style="height: 35px" class="rounded-circle me-2"/> {{ od.csrAgentFullname }}</td>
                      <td>{{ od.orderType }}</td>
                      <td>{{ od.date_created_gmt.split('T')[0] }}</td>
                      <td class="d-none">{{ baseURL+'/wp-admin/post.php?post='+od.id+'&action=edit' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              
            </div>
            
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
    
<script lang="js">
import { defineComponent } from 'vue'
import appConstants from '@/constants/app.constants';
//import store from '../store'
//import $ from "jquery";
import moment from 'moment';
import axios from 'axios';
import Mixin from '../mixins/global.mixin'
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import { Pie } from 'vue-chartjs'

ChartJS.register(ArcElement, Tooltip, Legend)
  
export default defineComponent({
  name: 'OrderReportsTIPM',
  mixins: [Mixin],
  components: {
    Pie,
    BounceLoader: BounceLoader
  },
  watch: {
    selectedCSR: function () {
      this.filterCSROrders();
    },
    selectedOrderTypes: function () {
      this.filterCSROrders();
    },
    seachingForOrderNumber: function () {
      this.filterCSROrders();
    },
    seachingForOrderNumberCustomer: function () {
      this.filterCustomerOrders();
    }
  },
  data() {
    return {
      dateTo: moment().format('YYYY-MM-DD'),
      dateFrom: moment().subtract(2, 'days').format('YYYY-MM-DD'),
      //orderListModal: new Modal(document.getElementById('orders-handled')),
      page: 1,
      wooOrderListIds: [],
      wooOrderList: [],
      csrOrders: [],
      csrOrdersIds: [],
      customerOrders: [],
      customerOrdersIds: [],
      shippingLabelOrders: [],
      shippingLabelOrdersIds: [],
      shippingLabelOrdersTemp: [],
      webkitURLooShipmentOrderListTemp: [],
      wooShipmentOrderList: [],
      results: [],
      mostSearchedVehicles: [],
      top10MostSearchedVehicles: [],
      topSellingProducts: [],
      top10SellingProducts: [],
      modalOrdersData: [],
      provideYourVinTracker: null,
      isOrdersLoading: false,
      isQueueRunning: false,
      isRTAChecked: true,
      isRMAChecked: true,
      isCloningChecked: true,
      isRepairChecked: true,
      isProductCloningChecked: true,
      totalShippingCost: 0,
      shippingCalculationCount: 0,
      shippingCostProgress: 0,
      totalSales: 0,
      totalShipping: 0,
      totalDiscount: 0,
      totalRefunds: 0,
      totalSalesBreakDown: [],
      baseURL: appConstants.TIPMURL,
      apiQueue: [],
      numberFormater: new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
      rvcData: null,
      rvcStatsLoading: true,
      lidData: null,
      lidStatsLoading: true,
      handledBy: null,
      csrAgentLst: appConstants.CSR_AGENTS_LOOKUP,
      csrOrderTypes: appConstants.CSR_ORDER_TYPES,
      selectedOrderTypes: [
        "RMA - Repair",
        "RMA - Cloning",
        "RMA - Standard Replacement",
        "RMA - Immediate Replacement",
        "Normal Purchase"
      ],
      selectedCSR: [
        "henry@tipmrebuilders.com",
        "amal@tipmrebuilders.com",
        "chase@tipmrebuilders.com",
        "franz@maksdts.com",
        "wayne@maksdts.com",
        "greg@maksdts.com",
        "jevic@maksdts.com",
        "toni@maksdts.com",
        "charlesdeo@maksdts.com"
      ],
      seachingForOrderNumber: null,
      seachingForOrderNumberCustomer: null,
      filteredCSROrders: [],
      filteredCustomerOrders: [],
    }
  },
  methods: {
    resetData: function () {
        this.wooOrderListIds = [];
        this.wooOrderList = [];
        this.wooShipmentOrderListTemp = [];
        this.wooShipmentOrderList = [];
        this.csrOrders = [];
        this.csrOrdersIds = [];
        this.customerOrders = [];
        this.customerOrdersIds = [];
        this.shippingLabelOrders = [];
        this.shippingLabelOrdersTemp = [];
        this.shippingLabelOrdersIds = [];
        this.apiQueue = [];
        this.results = [];
        this.provideYourVinTracker = null,
        this.shippingCalculationCount = 0;
        this.isRTAChecked = true,
        this.isRMAChecked = true,
        this.isCloningChecked = true,
        this.isRepairChecked = true,
        this.isProductCloningChecked = true,
        this.totalShippingCost = 0;
        this.totalSales = 0;
        this.totalShipping = 0;
        this.totalDiscount = 0;
        this.totalRefunds = 0;
        this.totalSalesBreakDown = [],
        this.mostSearchedVehicles = [];
        this.top10MostSearchedVehicles = [];
        this.topSellingProducts = [];
        this.shippingCostProgress = 0;
        this.modalOrdersData = [];
        this.page = 1;
        this.rvcData = null;
        this.lidData = null;
        this.isOrdersLoading = false;
    },
    resetCSRAgentFilter: function () {
      this.csrAgentLst.forEach((csr) => {
        this.selectedCSR.push(csr.email);
      });
      this.filterCSROrders();
    },
    resetOrderTypeFilter: function () {
      this.csrOrderTypes.forEach((ot) => {
        this.selectedOrderTypes.push(ot.TITLE);
      });
      this.filterCSROrders();
    },
    submitDateRange: function () {
      this.resetData();
      this.getWooOrdersBetweenDates();
      this.getVehicleConfigurationStats();
      this.getMostSearchedVehicles();
      this.getTopSellingProducts();
      this.getProvideVinMethodsStats();
      this.getWooTotalSales();
    },
    getWooOrdersBetweenDates: function () {
      this.isOrdersLoading = true;
      axios.get(this.baseURL+'/wp-json/wc/v3/orders?before='+this.dateTo+'T00:00:00&after='+this.dateFrom+'T00:00:00&page='+this.page+'&per_page=100&status=completed,processing',{ crossdomain: true, headers : { 'Authorization' : appConstants.TIPM_AUTH} })
      .then((response) => {

        response.data.forEach((order) => {
          if (this.wooOrderList.filter((or) => or.id == order.id).length == 0) {
            order.orderType = this.csrOrderTypes.filter((ot) => ot.VALUE == 0)[0].TITLE;
            let productIds = [];
            order.line_items.forEach((li) => { productIds.push(li.product_id) });
            switch (true) {
              case (order.meta_data.filter((or) => or.key == 'additional_order_handled_by' && or.value == 'Customer').length > 0): 
                this.customerOrders.push(order);
                this.filteredCustomerOrders.push(order);
                this.customerOrdersIds.push(order.id);
                break;
              case (order.meta_data.filter((or) => or.key == '_billing_shop_as_client_handler_user_id').length > 0):
                var csrID = order.meta_data.filter((or) => or.key == '_billing_shop_as_client_handler_user_id')[0].value;
                if (this.csrAgentLst.filter((csr) => csr.id == csrID).length) {
                  var data = this.csrAgentLst.filter((csr) => csr.id == csrID)[0];
                  order.csrAgentEmail = data.email;
                  order.csrAgentImg = data.image;
                  order.csrAgentFullname = data.name;
                  
                  
                  this.csrOrderTypes.forEach((ot) => {
                    if (productIds.includes(ot.VALUE)) {
                      order.orderType = ot.TITLE;
                    }
                  });

                  this.csrOrders.push(order);
                  this.filteredCSROrders.push(order);
                  this.csrOrdersIds.push(order.id);
                } else {
                  this.customerOrders.push(order);
                  this.filteredCustomerOrders.push(order);
                  this.customerOrdersIds.push(order.id);
                }
                break;
            }


            let forCloningItems = (order.line_items.filter((li) => li.meta_data.filter((limeta) => limeta.key == '_product_tags' && limeta.value.toLowerCase().includes('clone')).length ))
            if (productIds.includes(appConstants.SHIPPING_LABEL_PRINTING_PRODUCT_TIPM.REPAIR.VALUE) ||
            productIds.includes(appConstants.SHIPPING_LABEL_PRINTING_PRODUCT_TIPM.CLONING.VALUE) ||
            productIds.includes(appConstants.SHIPPING_LABEL_PRINTING_PRODUCT_TIPM.STANDARD_REPLACEMENT.VALUE) ||
            productIds.includes(appConstants.SHIPPING_LABEL_PRINTING_PRODUCT_TIPM.IMMEDIATE_REPLACEMENT.VALUE) || forCloningItems.length) {
              
              if (forCloningItems.length) {
                order.orderType = appConstants.SHIPPING_LABEL_PRINTING_PRODUCT_TIPM.PRODUCT_CLONING.TITLE;
              }

              this.shippingLabelOrders.push(order);
              this.shippingLabelOrdersTemp.push(order);
              this.shippingLabelOrdersIds.push(order.id);
            }

            this.wooOrderListIds.push(order.id);
            this.wooOrderList.push(order);
          } 
        });

        if (response.data.length == 100) {
          this.page = this.page+1;
          this.getWooOrdersBetweenDates();
        } else {
          //this.toggleFullScreenSpinner(false);
          this.isOrdersLoading = false;
        }
      });
    },
    getVehicleConfigurationStats: function () {
      axios.get(appConstants.TIPMURL+'/wp-json/wc/v3/vehicle-config-stats/'+this.dateFrom+'/'+this.dateTo, { crossdomain: true, headers : { 'Authorization' : appConstants.TIPM_AUTH} })
      .then((response) => {
        this.rvcData = {
          labels: ['RVC Purchased', 'Declined'],
          datasets: [
            {
              backgroundColor: ['#51E1E5', '#BDCCD4'],
              data: [response.data.purchase, response.data.declined]
            }
          ]
        };
        this.lidData = {
          labels: ['Black', 'Blue', 'Red', 'Green', 'Yellow', 'Purple', "No Lid"],
          datasets: [
            {
              backgroundColor: ['#001823', '#0D6EFD', '#DC3545', '#198754', '#FFC107', '#6F42C1', '#BDCCD4'],
              data: [response.data.color_counts.Black, response.data.color_counts.Blue, response.data.color_counts.Red, response.data.color_counts.Green, response.data.color_counts.Yellow, response.data.color_counts.Purple, response.data.color_counts.None]
            }
          ]
        };
        this.rvcStatsLoading = false;
        this.lidStatsLoading = false;
      });
    },
    getMostSearchedVehicles: function () {
      axios.get(appConstants.TIPMURL+'/rest/vehicle_search_history.php?type=get_data_by_date_range&site=tipm&date_from='+this.dateFrom+'&date_to='+this.dateTo, { crossdomain: true})
      .then((response) => {
        this.mostSearchedVehicles = response.data;
        this.mostSearchedVehicles.sort((a, b) => b.search_count - a.search_count);
        this.top10MostSearchedVehicles = this.mostSearchedVehicles.slice(0, 10);
      });
    },
    getTopSellingProducts: function () {
      axios.get(appConstants.TIPMURL+'/wp-json/myplugin/v1/top_products/?start_date='+this.dateFrom+'%2000%3A00%3A00&end_date='+this.dateTo+'%2023%3A59%3A59', { crossdomain: true})
      .then((response) => {
        this.topSellingProducts = response.data;
        this.top10SellingProducts = this.topSellingProducts.slice(0, 10);
      });
    },
    getProvideVinMethodsStats: function () {
      axios.get(appConstants.TIPMURL+'/rest/provide_your_vin_tracker.php?type=get_data_by_date_range&site=tipm&date_from='+this.dateFrom+'&date_to='+this.dateTo, { crossdomain: true})
      .then((response) => {
        //this.provideYourVinTracker = response.data;
        var emailCount = 0;
        var smsCount = 0;
        var customerPortal = 0;
        if (response.data) {
          response.data.forEach((dta, idx, array) => {
            switch (dta.method) {
              case 'email':
                emailCount = emailCount + 1;
                break;
              case 'sms':
                smsCount = smsCount + 1;
                break;
              default:
                customerPortal = customerPortal + 1;
                break;
            }

            if (idx+1 == array.length) {
              this.provideYourVinTracker = {
                labels: ['Email', 'SMS', 'Customer Portal'],
                datasets: [
                  {
                    backgroundColor: ['#727CF5', '#3D0A91', '#8C68CD'],
                    data: [emailCount,smsCount ,customerPortal]
                  }
                ]
              }
            }
          });
        }
        
      });
    },
    getWooTotalSales: function () {
      ///wp-json/wc/v3/reports/sales?date_min=2016-05-03&date_max=2016-05-04
      axios.get(this.baseURL+'/wp-json/wc/v3/reports/sales?date_min='+this.dateFrom+'&date_max='+this.dateTo,{ crossdomain: true, headers : { 'Authorization' : appConstants.TIPM_AUTH} })
      .then((response) => {
        console.log(response.data);
        this.totalSales = response.data[0].total_sales;
        this.totalShipping = response.data[0].total_shipping;
        this.totalDiscount = response.data[0].total_discount;
        this.totalRefunds = response.data[0].total_refunds;
        this.totalSalesBreakDown = response.data[0].totals;
      });
    },
    filterCSROrders: function () {
      if (this.seachingForOrderNumber) {
        this.filteredCSROrders = this.csrOrders.filter((fitems) => this.selectedCSR.includes(fitems.csrAgentEmail) && this.selectedOrderTypes.includes(fitems.orderType) && fitems.number.includes(this.seachingForOrderNumber));
      } else {
        this.filteredCSROrders = this.csrOrders.filter((fitems) => this.selectedCSR.includes(fitems.csrAgentEmail) && this.selectedOrderTypes.includes(fitems.orderType));
      }
    },
    filterCustomerOrders: function () {
      if (this.seachingForOrderNumberCustomer) {
        this.filteredCustomerOrders = this.customerOrders.filter((fitems) => fitems.number.includes(this.seachingForOrderNumberCustomer));
      } else {
        this.filteredCustomerOrders = this.customerOrders;
      }
    },
    showOrdersHeatMap: function () {
      var root = am5.Root.new("chartdiv");
      root.setThemes([
        am5themes_Animated.new(root)
      ]);
        
      var chart = root.container.children.push(
        am5map.MapChart.new(root, {
          //panX: "rotateX",
          //panY: "rotateY",
          homeZoomLevel: 13,
          homeGeoPoint: {
            longitude: -100.17232754112253,
            latitude: 39.22231405286368
          },
          //projection: am5map.geoNaturalEarth1(),
          projection: am5map.geoMercator()
        })
      );

      var backgroundSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));
      backgroundSeries.mapPolygons.template.setAll({
        fill: root.interfaceColors.get("alternativeBackground"),
        fillOpacity: 0,
        strokeOpacity: 0,
      });

      backgroundSeries.data.push({
        geometry: am5map.getGeoRectangle(90, 180, -90, -180)
      });

      var polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_worldLow,
          exclude: ["AQ"],
        })
      );

      polygonSeries.mapPolygons.template.setAll({
        fill: root.interfaceColors.get("alternativeBackground"),
        fillOpacity: 0.15,
        strokeWidth: 0.5,
        stroke: root.interfaceColors.get("background")
      });
        
      polygonSeries.events.on("datavalidated", () => {
        chart.goHome();
      });

      var circleTemplate = am5.Template.new({
        tooltipHTML : "<img src='{thumbnail}' class='rounded-circle me-2 mb-2 bg-white' style='height: 30px; width: 30px'/><span class='text-white'>{name}</span><div class='mb-0 text-white d-flex flex-column' style='font-size:12px;'><div>{address}</div><div>{contact}</div></div>"
      });

      var bubbleSeries = chart.series.push(
        am5map.MapPointSeries.new(root, {
          calculateAggregates: true,
          valueField: "value",
          dataField: "value",
          polygonIdField: "id"
        })
      );

      bubbleSeries.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 10,
            templateField: "circleTemplate"
          }, circleTemplate)
        });
      });

      bubbleSeries.set("heatRules", [{
        target: circleTemplate,
        min: 5,
        max: 60,
        key: "radius",
        property: "radius",
        dataField: "value"
      }]);

      chart.series.push(
        am5map.ClusteredPointSeries.new(root, {
          minDistance: 30,
          geoJSON: am5geodata_worldLow,
          exclude: ["AQ"],
          autoScale: true,
          scatterDistance: 10,
          scatterRadius: 10,
          stopClusterZoom: 0.9
        })
      );
      chart.set("zoomControl", am5map.ZoomControl.new(root, {}));
    },
  },
  mounted() {
    this.getWooOrdersBetweenDates();
    this.getVehicleConfigurationStats();
    this.showOrdersHeatMap();
    this.getMostSearchedVehicles();
    this.getTopSellingProducts();
    this.getProvideVinMethodsStats();
    this.getWooTotalSales();

    setTimeout( () => {
      this.toggleFullScreenSpinner(false);
    }, 500);
  }
});
</script>
    