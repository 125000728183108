import moment from 'moment'
import store from '../store';
import { mapState } from 'vuex';

export default {
    computed: mapState([
        'isLoading'
    ]),
    methods: {
        toggleFullScreenSpinner: function (value) {
            store.commit('TOGGLE_FULLSCREEN_SPINNER', value);
        },
        cleanString: function (string) {
            if (string) {
                return string.replace(/&amp;/g,'&');
            } else {
                return null;
            }
        },
        round: function (num, decimalPlaces = 0) {
            num = Math.round(num + "e" + decimalPlaces);
            return Number(num + "e" + -decimalPlaces);
        },
        cleanVinNumber: function (vin) {
            return vin.replace(/I/gi,'1').replace(/O/gi,'0').replace(/Q/gi,'0');
        },
        formatRegularPrice (price) {
            var finalPrice = null;
            if (!price.includes('.')) {
                finalPrice = price+'.00';
            } else {
                if (price.split('.')[1].length == 1) {
                    finalPrice = price+'0';
                } else {
                    finalPrice = price;
                }
            }
            return finalPrice;
        },
        validateEmail: function (email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        },
        deviceType: function () {
            const ua = navigator.userAgent;
            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                return "tablet";
            }
            else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
                return "mobile";
            }
            return "desktop";
        },
        isMobile: function () {
            if (this.deviceType == 'mobile' || this.deviceType == 'tablet') {
                return true;
            } else {
                return false;
            }
        },
        formatDate: function (date) {
           return moment(date).fromNow();
        },
        validateVin: function (vin) {
            if (vin == "11111111111111111") { return false; }
            if (!vin.match("^([0-9a-hj-npr-zA-HJ-NPR-Z]{10,17})+$")) { return false;}
            var letters = [{ k: "A", v: 1 }, { k: "B", v: 2 }, { k: "C", v: 3 },
            { k: "D", v: 4 }, { k: "E", v: 5 }, { k: "F", v: 6 }, { k: "G", v: 7 },
            { k: "H", v: 8 }, { k: "J", v: 1 }, { k: "K", v: 2 }, { k: "L", v: 3 },
            { k: "M", v: 4 }, { k: "N", v: 5 }, { k: "P", v: 7 }, { k: "R", v: 9 },
            { k: "S", v: 2 }, { k: "T", v: 3 }, { k: "U", v: 4 }, { k: "V", v: 5 },
            { k: "W", v: 6 }, { k: "X", v: 7 }, { k: "Y", v: 8 }, { k: "Z", v: 9 }];
            var weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
            var exclude = ["I", "O", "Q"];
            var val = 0;
            for (var idx = 0; idx < vin.length; idx++) {
                var item = vin.charAt(idx).toUpperCase();
                if (exclude.includes(function (x) { return x == item; })) { return false; }
                var pos = (item.match("^[0-9]+$") != null) ? parseInt(item) : letters.filter(function (letter) { return letter.k == item; })[0].v;
                val += (pos * weights[idx]);
            }
            var checksum = (val % 11);
            //  if (!(vin.charAt(8) == (checksum < 10 ? checksum.toString() : "X"))) {
            //     return 'ninth-digit-error';
            // }
            return (vin.charAt(8) == (checksum < 10 ? checksum.toString() : "X"));
        },
        exportTableToCsv: function (tableId) {
            // Variable to store the final csv data
            let csv_data = [];
      
            // Get the table element by ID
            let table = document.getElementById(tableId);
            if (!table) {
              console.error("Table with specified ID not found");
              return;
            }
       
            // Get each row data
            let rows = table.getElementsByTagName('tr');
            for (let i = 0; i < rows.length; i++) {
      
                // Get each column data
                let cols = rows[i].querySelectorAll('td,th');
      
                // Stores each csv row data
                let csvrow = [];
                for (let j = 0; j < cols.length; j++) {
      
                    // Get the text data of each cell
                    // of a row and push it to csvrow
                    csvrow.push(cols[j].textContent.trim());
                }
      
                // Combine each column value with comma
                csv_data.push(csvrow.join(","));
            }
      
            // Combine each row data with new line character
            csv_data = csv_data.join('\n');
      
            // Call this function to download csv file  
            this.downloadCSVFile(csv_data, tableId);
        },
        downloadCSVFile: function (csv_data, tableId) {
    
        // Create CSV file object and feed
        // our csv_data into it
        let CSVFile = new Blob([csv_data], {
            type: "text/csv"
        });
    
        // Create to temporary link to initiate
        // download process
        let temp_link = document.createElement('a');
    
        // Download csv file
        temp_link.download = tableId+'-'+this.dateFrom+'-to-'+this.dateTo+".csv";
        let url = window.URL.createObjectURL(CSVFile);
        temp_link.href = url;
    
        // This link should not be displayed
        temp_link.style.display = "none";
        document.body.appendChild(temp_link);
    
        // Automatically click the link to
        // trigger download
        temp_link.click();
        document.body.removeChild(temp_link);
        },
        
    }
  };