<template>
    <el-menu
    :default-active="activeIndex"
    class="el-menu-demo horizontal-bar border-0"
    mode="horizontal"
    :ellipsis="false"
    @select="handleSelect"
    >
    
    <el-menu-item index="0" @click="toggleSideBar">
        <i class="fa-sharp fa-solid fa-bars" style="font-size: 20px"></i>
    </el-menu-item>
    <div class="flex-grow-1" tabindex="0"></div>
    <el-menu-item index="1">Processing Center</el-menu-item>
    <el-sub-menu index="2">
        <template #title>Workspace</template>
            <el-menu-item index="2-1">item one</el-menu-item>
            <el-menu-item index="2-2">item two</el-menu-item>
            <el-menu-item index="2-3">item three</el-menu-item>
        <el-sub-menu index="2-4">
        <template #title>item four</template>
            <el-menu-item index="2-4-1">item one</el-menu-item>
            <el-menu-item index="2-4-2">item two</el-menu-item>
            <el-menu-item index="2-4-3">item three</el-menu-item>
        </el-sub-menu>
    </el-sub-menu>
    </el-menu>
</template>

<script lang="js">
import store from '../store'
import { mapState } from 'vuex'

export default {
  name: 'HeaderComponent',
  computed: mapState([
    'isCollapse'
  ]),
  methods: {
    toggleSideBar: function () {
        store.commit('SET_SIDEBAR_TOGGLE', !this.isCollapse);
    }
  },
}
</script>