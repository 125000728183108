<template>
    <div class="d-flex justify-content-center align-items-center h-100">
        <div class="text-center">
            <i class="fa-duotone fa-code text-main mb-3" style="font-size: 100px;"></i>
            <h2 style="font-size: 3em">We Are Tunning</h2>
            <h1 style="font-size: 7em">OUR <span class="text-main">WEBSITE</span></h1>
            <hr/>
            <p>Our site is currently under construction but we are working hard to bring it to you</p>
        </div>
    </div>
</template>

<script lang="js">
export default {
  name: 'UnderConstruction'
}
</script>