<template>
    <el-menu
        active-text-color="#fff"
        active-background-color="#51E1E5"
        background-color="#313A46"
        class="el-menu-vertical-demo pt-3 sidebar-menu h-100"
        :default-active="activeTab"
        :collapse="isCollapse"
        text-color="#fff"
        @open="handleOpen"
        @close="handleClose"
    >
        <div class="d-flex justify-content-center align-items-center pt-2 pb-3" v-if="!isCollapse">
        <img src="https://dev.maksenterprisesllc.com/wp-content/uploads/2023/11/maks-enterprises-logo-dark-resized.png" style="height: 24px"/>
        </div>
        <div class="d-flex justify-content-center align-items-center pe-3 ps-3 pt-2 pb-3" v-else>
        <img src="https://dev.maksenterprisesllc.com/wp-content/uploads/2024/04/m-logo-dark.png" style="height: 25px" />
        </div>
        <br/>

        <el-menu-item index="1" @click="redirectToPage('/module-request', '1')">
            <el-icon><i class="fa-sharp-duotone fa-solid fa-car-circle-bolt"></i></el-icon>
            <span class="ms-3 pe-3">Module Request</span>
        </el-menu-item>
        <el-sub-menu index="2" >
            <template #title>
                <el-icon><i class="fa-sharp fa-regular fa-bag-shopping"></i></el-icon>
                <span class="ms-3 pe-3">Website Reports</span>
            </template>
            <el-menu-item index="2-1" @click="redirectToPage('/order-distrubution-tipm', '2-1')">
                <el-icon><i class="fa-solid fa-globe"></i></el-icon>
                <span class="ms-3 pe-3">TIPM</span>
            </el-menu-item>
            <el-menu-item index="2-2" @click="redirectToPage('/order-distrubution-ecm', '2-2')">
                <el-icon><i class="fa-solid fa-globe"></i></el-icon>
                <span class="ms-3 pe-3">ECM</span>
            </el-menu-item>
            <el-menu-item index="2-3" @click="redirectToPage('/order-distrubution-merger', '2-3')">
                <el-icon><i class="fa-solid fa-globe"></i></el-icon>
                <span class="ms-3 pe-3">MERGER</span>
            </el-menu-item>
        </el-sub-menu>
        <el-menu-item index="3" @click="redirectToPage('/ticket-list', '3')">
            <el-icon><i class="fa-sharp fa-regular fa-ticket"></i></el-icon>
            <span class="ms-3 pe-3">Tickets</span>
        </el-menu-item>
        <el-menu-item index="4" @click="redirectToPage('/reseller-applications', '4')">
            <el-icon><i class="fa-sharp fa-regular fa-file-signature"></i></el-icon>
            <span class="ms-3 pe-3">Reseller Applications</span>
        </el-menu-item>
        <el-menu-item index="5" @click="redirectToPage('/rma-tracker', '5')">
            <el-icon><i class="fa-sharp fa-regular fa-right-left"></i></el-icon>
            <span class="ms-3 pe-3">RMA Orders Tracker</span>
        </el-menu-item>
    </el-menu>
</template>


<script lang="js">
import store from '@/store';
import { mapState } from 'vuex';


export default {
  name: 'SideBarComponent',
  computed: mapState([
      'isCollapse',
      'activeTab',
      'lastDestination'
  ]),
  methods: {
    redirectToPage(destination, active) {
        this.$router.push(destination);
        store.commit('CURRENT_ACTIVE_TAB', active);
        store.commit('LAST_ACTIVE_TAB', destination);
    }
  }
}
</script>