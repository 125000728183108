<template>
  <div class="common-layout h-100">
    <el-container class="vh-100">
      <SideBarComponent />
      <el-container>
        <el-header class="p-0 horizontal-bar bg-white w-100">
          <HeaderComponent />
        </el-header>
        <el-main style="background-color: #F6F7FB;">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
    <div v-if="isLoading" id="popup-overlay">
    <bounce-loader :loading="isLoading" :color="color" :size="size"/>
    </div>
  </div>
</template>

<script lang="js">
import HeaderComponent from './components/HeaderComponent'
import SideBarComponent from './components/SideBarComponent'
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import { mapState } from 'vuex'
import Mixin from './mixins/global.mixin'
import $ from 'jquery'

export default {
  name: 'App',
  mixins: [Mixin],
  components: {
    SideBarComponent,
    HeaderComponent,
    BounceLoader: BounceLoader
  },
  watch:{
    $route: function () {
      this.toggleFullScreenSpinner(true);
      $('#chartdiv').empty();
    }
  },
  computed: mapState([
    'isLoading'
  ]),
  data() {
    return {
      color: '#51E1E5',
      size: '80px'
    }
  },
  methods: {
  },
}
</script>