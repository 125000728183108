<template>
    <UnderConstruction />
</template>
    
<script lang="js">
import { defineComponent } from 'vue'
import UnderConstruction from '../components/UnderConstruction';
import Mixin from '../mixins/global.mixin'
  
export default defineComponent({
    name: 'TicketList',
    mixins: [Mixin],
    components: {
        UnderConstruction
    },
    methods: {
    },
    mounted() {
      setTimeout( () => {
        this.toggleFullScreenSpinner(false);
      }, 500);
    }
});
</script>
    