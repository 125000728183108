import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router';
import store from './store'

const app = createApp(App)
.use(router)
.use(store);

app.use(ElementPlus)
router.isReady().then(() => {
    app.mount('#app');
});